import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import TimeAgo from "react-timeago";
import { Link } from "routes";
import ViewCount from '/components/viewCount';
import AuthorName from '/components/AuthorProfile/AuthorName';

const styles = theme => ({
  autherTime: {
    lineHeight: '15px',
    fontWeight: 'bold',
    fontSize: 9,
  },
  dot: {
    margin: '0px 7px',
    fontWeight: 'bold'
  },
});

class AuthorAndTime extends React.Component {
  render(){
  const { item, classes, linkColor, customStyle, views, viewCount } = this.props;
  const showAuthorName = false;
  return (
    <Typography className={classes.autherTime} variant="caption" style={customStyle}>
        {item.author && showAuthorName &&
        <>
          <AuthorName linkColor={linkColor} item={item.author} />
          <span className={classes.dot} >•</span>
          </>
        }
        <TimeAgo className={classes.date} date={item.published_date.date} />
        {/* {
            views &&  
            <span style={{ marginLeft: 20, opacity: 0.5 }}>
                <ViewCount viewCount={views} />
            </span>
        } */}
        {!item.playlist_id && views ? 
            <span style={{ paddingLeft: 10, opacity: 0.5 }} {...viewCount}>
              <ViewCount viewCount={views} />
            </span>
          : null
          }

    </Typography>
  );
}
}

AuthorAndTime.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AuthorAndTime);
