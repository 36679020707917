import React from 'react';
import { Link } from "routes";

class AuthorName extends React.Component {
    render(){
    const { item, linkColor } = this.props;
    let authorEmail = item && item.email ? item.email : 'noprofile';
    let authorSlug = item && item.slug ? item.slug : authorEmail;
    return (
        // <Link route="authorProfile" onClick={e => e.preventDefault()} params={{ id: authorSlug }}>
            <span className="anchor-link" style={{color: linkColor}}>
                {item.name}
            </span>
        // </Link>
    );
  }
  }

export default AuthorName;
