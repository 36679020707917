import React, { Component } from "react";
import Icon from '@material-ui/core/Icon';
import { makeFriendly } from '/utils/viewCountMakeFriendly';

function ViewCount (props) {
    const { viewCount } = props;
        return (
            <React.Fragment>
                <Icon style={{ fontSize: '0.75rem', marginRight: 3, paddingTop: 1 }}>remove_red_eye</Icon>
                <span>{makeFriendly(viewCount)}</span>
            </React.Fragment>
        );
}

export default ViewCount;
